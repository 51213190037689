
import { motion, AnimatePresence } from "framer-motion"
import { useState, useEffect } from 'react'
import Select from "react-select";
import { ClipLoader } from "react-spinners"
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import Swal from 'sweetalert2'
import emailjs from '@emailjs/browser'
import styles from './css/home.module.scss'

import './App.css'
import logo from './assets/logo.png'
import paul from './assets/paul.png'
import amy from './assets/amy.png'
import evan from './assets/evan.png'
import clients from './assets/clients.png'
import workIcon1 from './assets/work-icon-1.png'
import workIcon2 from './assets/work-icon-2.png'
import workIcon4 from './assets/work-icon-4.png'
import workIcon5 from './assets/work-icon-5.png'
import spain from './assets/spain.png'
import triangle from './assets/footer-triangle.png'
import platformMockup from './assets/platform-mockup.png'
import closeIcon from './assets/close.svg'
import videoFile from './assets/beach.mp4'
import insurance from './assets/icons/insurance.png'
import affiliate from './assets/icons/affiliate.png'
import events from './assets/icons/events.png'
import nie from './assets/icons/NIE.png'
import accommodation from './assets/icons/accommodation.png'
import friendsMatching from './assets/friends-matching.jpg'
import { languagesOptions, hobbiesOptions } from "../options";
const FlowCard = ({children,icon,id})=>{
  return(
    <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
    id={id} className="flowCard">
        <img src={icon} className="flowCardIcon" />
        <div className="flowCardTitle">
          {children}
        </div>
    </motion.div>
  )
}

const WorkCard = ({title,subtitle,icon,id})=>{
  return(
    <div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
    id={id} className="workCard">
        <img src={icon} className="workCardIcon" />
        <div className="workCardText">
          <div className="workCardTitle">{title}</div>
          <div className="workCardSubtitle">{subtitle}</div>
        </div>
    </div>
  )
}

const Dropdown = ({title,options, onChange})=>{

  return(
    <div className="dropdown">
        <div className="dropdownTitle">{title}</div>
        <select onChange={(event)=>{onChange(event.target.value)}}  className="dropdownInput">
            {
              options.map((option)=> <option key={option} value={option}>{option}</option>)
            }
        </select>
    </div>
  )
}

            

const Country = ({title,icon})=>{
  return(
    <div className="country">
        <img src={icon} className="countryIcon" />
        <div className="countryTitle">{title}</div>
    </div>
  )
}

const CountrySelect = ({question, value, onChange, countries, isMulti})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <Select
          isMulti={isMulti}
          name="colors"
          options={countries}
          className="countryInput"
          value={value}
          isSearchable={true}
          onChange={(val) => onChange(val)}
          menuPortalTarget={document.querySelector('body')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    </div>
  )
}

const Range = ({question, onChange, value, placeholder, currency})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <div className="radioOptions">
          <div className="rangeCurrency">{currency}</div>
          <input type="number" className="rangeInput" placeholder={placeholder} value={value} onChange={(event)=>{ onChange(event.target.value) }} />
        </div>
    </div>
  )
}

const Radio = ({question, options, onChange, selected})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <div className="radioOptions">
          {
            options.map((option)=>{
              return(
                (option === selected)?
                <div className="radioOptionSelected">{option}</div>
                :
                <div className="radioOption" onClick={(e)=>{ onChange(option) }}>{option}</div>
              )
            })
          }
        </div>
    </div>
  )
}

const DocumentsWindow = ({ isVisible, onClose }) => {


  const [workType, setWorkType] = useState('I Am A Freelancer')
  const [income, setIncome] = useState(10000)
  const [country, setCountry] = useState(['ES'])
  const [employerCountry, setEmployerCountry] = useState(null)
  const [countries, setCountries] = useState([])

  

  const goToSignUp = ()=>{
    document.location = 'https://app.simplynomad.co/signup?estimatedIncome='+income+'&country='+country.map((c)=>{ return c.value }).join(',')+'&workType='+workType+'employerCountry='+employerCountry.value
  }

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries)
        setCountry([data.userSelectValue])
        setEmployerCountry(data.userSelectValue);
      })
  }, [])

  return(
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="documentsWindowContainer"
      >
        <div className="documentsWindow">
          <div className="documentsWindowTitle">📁 Required Documents & Tax Strategy</div>
          <img src={closeIcon} onClick={(e)=>{onClose()}} className="closeIcon" />
          <div className="documentsWindowContent">
            <Radio question={'How Would You Describe Your Work?'} options={['I Am A Freelancer','I Own A Company','I Am An Employee']} selected={workType} onChange={(option)=>{ setWorkType(option) }} />
            {(workType === 'I Am A Freelancer')?<></>:<CountrySelect isMulti={false} question={'Where Is Your Employer / Company Located?'} countries={countries} value={employerCountry} onChange={(option)=>{ setEmployerCountry(option) }} />}
            <Range currency={'€'} question={'What Is Your Gross Annual Income?'} value={income} onChange={(option)=>{ setIncome(option) }} />
            <CountrySelect isMulti={true} question={'Which Countries Do You Hold A Passport From?'} countries={countries} value={country} onChange={(option)=>{ setCountry(option) }} />
            <div className="formSubmitButton" onClick={(e)=>{ goToSignUp() }}>
              Next <Emoji name="rocket" className="emoji" width={18} />
            </div>
          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
  )
}

const EmailSentPopup = ({ isVisible, onClose }) => {

  return(
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="documentsWindowContainer"
      >
        <div className="documentsWindow">
          <img src={closeIcon} onClick={(e)=>{onClose()}} className="closeIcon" />
          
        </div>
      </motion.div>
    )}
  </AnimatePresence>
  )
}

const MatchingWidget = ({countries}) => {

  const [hobbies, setHobbies] = useState([])
  const [languages, setLanguages] = useState([])
  const [country, setCountry] = useState([])

  const goToSignUp = ()=>{
    document.location = 'https://app.simplynomad.co/signup?type=social&hobbies='+hobbies.join(',')+'&languages='+languages.join(',')+'&country='+country
  }

  const multiStyles = {
    width:'500px',
    maxWidth:'80%',
    marginTop:'20px',
    alignSelf:'center',
    position: 'relative',
    left:'50%',
    transform: 'translate(-50%,0%)',
    boxShadow: 'none'
  }

  return(
        <div className="matchingWidget">
          <div className="matchingTitle">Make New Friends!</div>
          <div className="documentsWindowContent">
            <div className="selectSearchTitle">Tell Us About Your Hobbies</div>
            <Select 
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...multiStyles
              }),
              option: (styles, { data, isDisabled}) => {
                return {
                  ...styles
                } 
              },
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                background: '#a5b9ff',
                color: '#001a79'
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                background: '#a5b9ff',
                color: '#001a79'
              })
            }}
            defaultValue={[]} 
            onChange={(v)=>{ setHobbies(v) }} 
            isMulti 
            options={hobbiesOptions} 
            value={hobbies} 
            name="hobbies" 
            placeholder="Choose your hobbies" />

          <div className="selectSearchTitle">What Languages Do You Speak?</div>
            <Select 
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                ...multiStyles
              }),
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                background: '#a5b9ff',
                color: '#001a79'
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                background: '#a5b9ff',
                color: '#001a79'
              })
            }}
            defaultValue={[]} 
            onChange={(v)=>{ setLanguages(v) }} 
            isMulti 
            options={languagesOptions} 
            value={languages} 
            name="languages" 
            placeholder="" /> 
            <CountrySelect isMulti={false} question={'Where Are Your From?'} countries={countries} value={country} onChange={(option)=>{ setCountry(option) }} />
            
            <div className="formSubmitButton" onClick={(e)=>{ goToSignUp() }}>
              Meet New Friends 
              <Emoji name="smiling-face-with-smiling-eyes" className="emoji" width={24} />
            </div>
          </div>
        </div>
  )
}

const DocumentsWidget = ({countries}) => {

  const [workType, setWorkType] = useState('I Am A Freelancer')
  const [income, setIncome] = useState(10000)
  const [country, setCountry] = useState(['ES'])
  const [employerCountry, setEmployerCountry] = useState(null)
  const goToSignUp = ()=>{
    document.location = 'https://app.simplynomad.co/signup?estimatedIncome='+income+'&country='+country.map((c)=>{ return c.value }).join(',')+'&workType='+workType+'employerCountry='+employerCountry.value
  }

  return(
        <div className="documentsWidget">
          <div className="documentsWindowTitle">📁 Required Documents & Tax Strategy</div>
          <div className="documentsWindowContent">
            <Radio question={'How Would You Describe Your Work?'} options={['I Am A Freelancer','I Own A Company','I Am An Employee']} selected={workType} onChange={(option)=>{ setWorkType(option) }} />
            {(workType === 'I Am A Freelancer')?<></>:<CountrySelect isMulti={false} question={'Where Is Your Employer / Company Located?'} countries={countries} value={employerCountry} onChange={(option)=>{ setEmployerCountry(option) }} />}
            <Range currency={'€'} question={'What Is Your Gross Annual Income?'} value={income} onChange={(option)=>{ setIncome(option) }} />
            <CountrySelect isMulti={true} question={'Which Countries Do You Hold A Passport From?'} countries={countries} value={country} onChange={(option)=>{ console.log(option);setCountry(option) }} />
            <div className="formSubmitButton" onClick={(e)=>{ goToSignUp() }}>
              See What You Need <Emoji name="rocket" className="emoji" width={18} />
            </div>
          </div>
        </div>
  )
}


const Review = ({name, image, text, location}) => {

  return(
        <div className="review">
            <img src={image} className="reviewImage" />
            <div className="reviewName">{name}</div>
            <div className="reviewLocation">{location}</div>
            <div className="reviewStars">
              <Emoji name="star" className="emoji" width={32} />
              <Emoji name="star" className="emoji" width={32} />
              <Emoji name="star" className="emoji" width={32} />
              <Emoji name="star" className="emoji" width={32} />
              <Emoji name="star" className="emoji" width={32} />
            </div>
            <div className="reviewText">{text}</div>
        </div>
  )
}



function App() {

  const [documentsWindowOpen, setDocumentsWindowOpen] = useState(false)
  const [formEmail, setFormEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState([])

  const goToSignUp = ()=>{
    document.location = 'https://app.simplynomad.co/signup'
  }

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
    .then((response) => response.json())
    .then((data) => {
      setCountries(data.countries)
    })
  }, [])

  const sendEmail = async(e)=>{
    e.preventDefault()
    
    await setLoading(true)

    if(!formEmail.includes('@') || !formEmail.includes('.')){
      Swal.fire(
        'Message Not Sent!',
        'Please Enter A Valid Email To Start.',
        'error'
      )

      setLoading(false)
    }

    emailjs.send('service_91jtlma', 'simply_nomad', {
      name: '',
      email: formEmail,
      message: '',
      income: '',
      type: '',
      passports: ''
    }, 'h8beUKjFjcEy8tO0m')
      .then((result) => {
            Swal.fire(
          'Your Message Was Sent!',
          'We will get back to you shortly with access to our platform.',
          'success'
        )
        setLoading(false)
        setFormEmail('')
      }, (error) => {
        Swal.fire(
          'Message Not Sent!',
          'Please Try Again',
          'error'
        )
        console.log(error)
        setLoading(false)
      });
  }
  
  return (
    <EmojiProvider data={emojiData}>
    <div className="app">
      <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="header">
        <div className="headerLogo">
          <img src={logo} className="headerLogoImage" />
          SimplyNomad
        </div>

        <div className="headerButtons">
          <a href={'./nie'}><div className="headerMenuItem">NIE & Tax</div></a>
          <div className="headerMenuItem">Insurance</div>
          <div className="headerMenuItem">Nomad Events</div>
          <div className="headerMenuItem">Affiliate Program</div>
          <div onClick={(e)=>{ document.location = 'https://app.simplynomad.co/signup' }} className="headerSignupButton">Get Started <Emoji name="grinning-face-with-smiling-eyes" className="emoji" width={18} /></div>
        </div>
      </motion.div>

      <div className="landingContainer">
        <div className="landingContainerLeft">
          <div className="registerCard">
            <div className="registerCardTitle">Moving To Spain As A Digital Nomad? We Have Everything Covered.</div>
            <div className="registerCardSubtitle">
            Everything from NIE & tax-planning to insurance & making friends. Get started to see how we can make your life easy.
            <br/><br/>
            </div>
            <input type="text" className="registerCardEmail" placeholder='Email' onChange={(e)=>{ setFormEmail(e.target.value) }} value={formEmail} />
            <br/><br/> 
            {
              (loading)?
              <ClipLoader  size={30} color={"#3600D7"} />
              :
              <div className="formSignupButton" onClick={(e)=>{ goToSignUp() }}>
                Join Us Now <Emoji name="rocket" className="emoji" width={18} />
              </div>
            }

              <div className="trustedBy">
                <img src={clients} className="trustedByClients" />
                <div className="trustedByTextContainer">
                  <div className="trustedByStars">
                    <Emoji name="star" className="emoji" width={16} />
                    <Emoji name="star" className="emoji" width={16} />
                    <Emoji name="star" className="emoji" width={16} />
                    <Emoji name="star" className="emoji" width={16} />
                    <Emoji name="star" className="emoji" width={16} />
                  </div>
                  <div className="trustedByText">
                    Trusted By Over 300 Digital Nomads Living In Spain.
                  </div>
                </div>
              </div>

          </div>
        </div>
        <div className="landingContainerRight">
        <video  className="beach" autoplay={true} loop  autoPlay muted src={videoFile}>
            <source src={videoFile} type="video/mp4" />
          </video>
        </div>
      </div>


      <div className="howItWorks"  style={{paddingBottom:'0px',marginBottom:'0px'}}>
        <div className="separator"></div>
        <div className="sectionTitle">Our Nomad Services</div>
        <div className="offersMenu">
          <div className="offersMenuItemUp" style={{marginBottom:'40px'}}>
            <img src={accommodation} className={styles.offerIcon} /><br/>
            Accommodation
          </div>
          <div className="offersMenuItemDown" style={{marginTop:'40px'}}>
          <img src={insurance} className={styles.offerIcon} /><br/>
            Insurance
          </div>
          <div className="offersMenuItemUp" style={{marginBottom:'40px'}}>
            <img src={nie} className={styles.offerIcon} /><br/>
            Tax Setup & Automation
          </div>
          <div className="offersMenuItemDown" style={{marginTop:'40px'}} >
          <img src={events} className={styles.offerIcon} /><br/>
            Nomad Events
          </div>
          <div className="offersMenuItemUp" style={{marginBottom:'40px'}} >
          <img src={affiliate} className={styles.offerIcon} /><br/>
            Affiliate Program
          </div>
        </div>
      </div>

      <div className="howItWorks" style={{paddingBottom:'0px',marginTop:'40px'}}>
        <div className="separator"></div>
        <div className="sectionTitle">How Can We Help You?</div>
        <div className="sectionColumns">
          <div className="sectionColumnLeft">
            <WorkCard icon={workIcon1} id="workCard1" title="Connect With Other Digital Nomads" subtitle="Our platform lets you meet other digital nomads with similar interests, with our advanced matching system. Our events partners also provide great ways to meet people." />
            <WorkCard icon={workIcon2} id="workCard2" title="NIE, Visa & Tax Registration In Spain" subtitle="From Visa applications to local registration for taxes and bank account setup, we can help you manage and apply for your required documents efficiently." />
            <WorkCard icon={workIcon5} id="workCard3" title="Insurance Coverage" subtitle="Get reliable health and travel coverage for your time in Spain and beyond. Understand exactly what you are getting, at a great price." />
            <WorkCard icon={workIcon4} id="workCard4" title="Set Up As An 'Autónomo' or 'SL'" subtitle="Whether you are looking to establish a company in a foreign country to reduce taxes or in your new country, we can help you establish and maintain your business, with legal and accounting services." />
          </div>
          <div className="sectionColumnRight">
            <img src={platformMockup} className="mockup" />
            <br/><br/><br/>
            <div className="formSignupButton" onClick={(e)=>{ setDocumentsWindowOpen(true) }}>
              Instantly Plan Your Tax Strategy <Emoji name="rocket" className="emoji" width={18} />
            </div>
          </div>
        </div>
      </div>
      <br/><br/>
      <div className="howItWorks">
        <div className="separator"></div>
        <div className="sectionTitle">Expat Friends Matching & Groups</div>
        <div className="sectionSubtitle">Our advanced AI system helps you meet people with similar interests.</div>

        <div className="sectionColumns">
          <div className="sectionColumnLeftCountry">
              <div className="countries">
                <img src={friendsMatching} className="friends" />
              </div>
          </div>
          <div className="sectionColumnRightCountry">
            <MatchingWidget countries={countries} />
          </div>
        </div>
      </div>
      <br/><br/>
      <div className="howItWorks">
        <div className="separator"></div>
        <div className="sectionTitle">Amazing Teams In Spain 🇪🇸</div>
        <div className="sectionSubtitle">We work with nomads and freelancers coming from <b>anywhere</b>, looking to work and live in Spain.</div>

        <div className="sectionColumns">
          <div className="sectionColumnLeftCountry">
              <div className="countries">
                <Country icon={spain} title={''} />
              </div>
          </div>
          <div className="sectionColumnRightCountry">
            <DocumentsWidget countries={countries} />
          </div>
        </div>
      </div>


      <div className="howItWorks">
        <div className="separator"></div>
        <div className="sectionTitle">Don't Just Take It From Us</div>
        <div className="reviews">
          <Review name={"Evan Power"} image={evan} location={'Valencia'} text={"I found it difficult to find reliable accountants and a straightforward platform for nomads in Spain. Simply Nomad made the tax registration process simple. They set me up in just 1 week."} />
          <Review name={"Paul"} image={paul} location={'Barcelona'} text={"The app gave me all the information I needed to start working remotely once I arrived in Spain. Before, I had been looking for 5 months for an accountant. I would definitely recommend it."} />
          <Review name={"Amy"} image={amy} location={'Sevilla'} text={"I used the platform for insurance was able to get health insurance in Spain quickly."} />
        </div>
      </div>

      <div className="howItWorks">
        <div className="separator"></div>
        <img src={triangle} className="bottomTriangle"></img>
        <div className="footerCard">
            <div className="registerCardTitle">Ready To Take A Look?</div>
            <div className="registerCardSubtitle">
              Get access to our hassle-free NIE system and a convenient platform to help you optimise your taxes as a freelancer or digital nomad. 
              <br/><br/>
            </div>
            <input type="text" className="registerCardEmail" placeholder='Email' onChange={(e)=>{ setFormEmail(e.target.value) }} value={formEmail} />
            <br/><br/> 
            {
              (loading)?
              <ClipLoader  size={30} color={"#3600D7"} />
              :
              <div className="formSignupButton" onClick={(e)=>{ goToSignUp() }}>
                Apply For An Accountant  <Emoji name="rocket" className="emoji" width={18} />
              </div>
            }

          </div>
      </div>

      <div className="footer">
        <div className="footerMenu">
          <a href="./"><Emoji name="house" className="emoji" width={32} /> Home</a><br/><br/>
          <a href="https://app.simplynomad.co/signup"><Emoji name="rocket" className="emoji" width={32} />Apply Now</a><br/><br/>
          <a href=""><Emoji name="page-facing-up" className="emoji" width={32} /> Terms & Conditions</a>
        </div>
        <div className="footerCopyright">
          © Copyright Simply Nomad Co. 2024 
        </div>
      </div>
      <DocumentsWindow isVisible={documentsWindowOpen} onClose={()=>{setDocumentsWindowOpen(false)}} />
      <EmailSentPopup />
    </div>
    </EmojiProvider>
  );
}

export default App;
