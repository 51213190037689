import React from 'react'
import { Routes, Route, BrowserRouter} from 'react-router-dom'
import Home from './pages/Home'
import Nie from './pages/Nie'

export const App = () => {

  return (
          <BrowserRouter>
              <Routes>
                <Route  path='/' element={<Home />} />
                <Route  path='/nie' element={<Nie />} />
              </Routes>
          </BrowserRouter>
  )
}


export default App;
